var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data && Array.isArray(_vm.configs))?_c('div',{staticClass:"rra-detail"},[(_vm.activeMenu == 7)?_c('rra-pra-detail-dusun-modal',{attrs:{"data":_vm.detailDusunData},on:{"close":function($event){_vm.detailDusunData = null}}}):_vm._e(),(_vm.activeTab == 1 && !_vm.data.mainPra)?_c('div',[_c('v-btn',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.$router.push({
          query: {
            view: 'create',
            id: _vm.$route.query.id,
            type: 'pra',
            scooping_visit_code: _vm.scoopingData.data_no,
            scooping_visit_village: _vm.scoopingData.desas_name,
            rra_code: _vm.rraCode,
            scooping_visit_start: _vm.scoopingData.start_scooping_date,
            scooping_visit_end: _vm.scoopingData.end_scooping_date,
          },
        })}}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',[_vm._v("Tambah Data Pra")])],1)],1):_vm._e(),(
      _vm.mainConfig[_vm.activeTab][_vm.activeMenu] &&
      (_vm.activeTab !== 1 || (_vm.activeTab == 1 && _vm.data.mainPra))
    )?_c('div',[(_vm.mainConfig[_vm.activeTab][_vm.activeMenu].mainData)?_c('rra-pra-detail-card',{attrs:{"data":_vm.data[_vm.mainConfig[_vm.activeTab][_vm.activeMenu].getter],"scoopingData":_vm.scoopingData,"config":_vm.mainConfig[_vm.activeTab][_vm.activeMenu].fields}}):_vm._e(),(_vm.mainConfig[_vm.activeTab][_vm.activeMenu].card)?_c('rra-pra-detail-card',{attrs:{"data":_vm.data[_vm.mainConfig[_vm.activeTab][_vm.activeMenu].getter],"scoopingData":_vm.scoopingData,"config":_vm.mainConfig[_vm.activeTab][_vm.activeMenu].fields,"ic":_vm.mainConfig[_vm.activeTab][_vm.activeMenu].class}}):(
        !_vm.mainConfig[_vm.activeTab][_vm.activeMenu].mainData &&
        !_vm.mainConfig[_vm.activeTab][_vm.activeMenu].card &&
        ((_vm.activeTab == 1 && _vm.data.mainPra) || _vm.activeTab == 0)
      )?_c('rra-pra-detail-table',{attrs:{"config":_vm.mainConfig[_vm.activeTab][_vm.activeMenu],"data":_vm.data[_vm.mainConfig[_vm.activeTab][_vm.activeMenu].getter],"wc":_vm.activeTab == 0 ? '' : 'mt-5',"description":_vm.mainConfig[_vm.activeTab][_vm.activeMenu].description
          ? _vm.data[_vm.activeTab == 0 ? 'mainRra' : 'mainPra'][
              _vm.mainConfig[_vm.activeTab][_vm.activeMenu].description.value
            ]
          : null},on:{"detailDusun":function($event){_vm.detailDusunData = $event}}}):_vm._e()],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }