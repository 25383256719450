var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"rra-pra-detail"},[_c('v-col',{attrs:{"md":"4","xl":"3"}},[_c('v-card',{staticClass:"rra-pra-detail-card mb-5",attrs:{"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("mdi-arrow-left-circle")]),_c('div',{staticClass:"d-flex flex row justify-content-between align-items-center pr-2"},[_c('h5',{staticClass:"mb-0 pb-0 pl-3"},[_vm._v("Detail RRA-PRA")])])],1),_c('div',{staticClass:"rra-pra-wrapper"},[_c('div',{staticClass:"rra-pra-list"},[_c('div',{staticClass:"rra-pra-item d-flex flex-column mb-3"},[_c('div',{staticClass:"label"},[_vm._v("Action")]),_c('div',{staticClass:"values"},[(_vm.$_sys.isAllowed('rra-pra-delete'))?_c('v-btn',{staticClass:"mr-2 mb-2",attrs:{"small":"","variant":"danger"},on:{"click":function($event){return _vm.onDelete()}}},[_c('v-icon',[_vm._v("mdi-delete-empty")]),_c('span',[_vm._v("Hapus")])],1):_vm._e(),(
                  _vm.data.rra &&
                  _vm.data.rra.mainRra &&
                  ['ready_to_submit', 'document_saving'].includes(
                    _vm.data.rra.mainRra.status
                  ) &&
                  _vm.$_sys.isAllowed('rra-pra-verification-create') &&
                  _vm.data.pra?.mainPra
                )?_c('v-btn',{staticClass:"mr-2 mb-2",attrs:{"small":"","variant":"success"},on:{"click":function($event){return _vm.onVerification('verification')}}},[_c('v-icon',[_vm._v("mdi-file-check-outline")]),_c('span',[_vm._v("Verifikasi")])],1):_vm._e(),(
                  _vm.data.rra &&
                  _vm.data.rra.mainRra &&
                  ['submit_review'].includes(_vm.data.rra.mainRra.status) &&
                  _vm.$_sys.isAllowed('rra-pra-unverification-create')
                )?_c('v-btn',{staticClass:"mr-2 mb-2",attrs:{"small":"","variant":"danger"},on:{"click":function($event){return _vm.onVerification('unverification')}}},[_c('v-icon',[_vm._v("mdi-undo-variant")]),_c('span',[_vm._v("Unverifikasi")])],1):_vm._e()],1)]),_vm._l((_vm.config.main_detail),function(f,i){return _c('div',{key:`main-data-${i}`,staticClass:"rra-pra-item"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(f[0]))]),(_vm.data.rra)?_c('div',{staticClass:"value"},[(f[1] === 'status')?_c('span',{class:{
                  'badge bg-light': _vm.data.rra.mainRra && !_vm.data.pra?.mainPra,
                  'badge bg-warning':
                    _vm.data.rra.mainRra &&
                    _vm.data.rra.mainRra.status == 'document_saving',
                  'badge bg-success':
                    _vm.data.rra.mainRra &&
                    _vm.data.rra.mainRra.status == 'submit_review',
                }},[(_vm.data.rra?.mainRra && !_vm.data.pra?.mainPra)?_c('span',[_vm._v("Draft")]):(
                    _vm.data.rra &&
                    _vm.data.rra.mainRra &&
                    _vm.data.rra.mainRra.status == 'document_saving'
                  )?_c('span',[_vm._v("Pending")]):(
                    _vm.data.rra?.mainRra &&
                    _vm.data.rra.mainRra.status == 'submit_review'
                  )?_c('span',[_vm._v("Terverifikasi")]):_vm._e()]):_c('span',{class:{
                  [f.length > 4 ? f[4] : '']: true,
                }},[_vm._v(_vm._s(_vm._f("parse")(_vm.getValue(f[1]),f.length > 2 ? f[2] : "no-empty")))])]):_vm._e()])})],2)])],1),_c('v-card',{staticClass:"rra-pra-detail-menu mb-5",attrs:{"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800"}},[_c('v-card-title',{staticClass:"border-dotted-bottom"},[_c('h5',{staticClass:"mb-0 pb-0"},[_vm._v("Informasi "+_vm._s(_vm.tabs[_vm.activeTab].label))])]),_c('div',{staticClass:"menu-list"},_vm._l((_vm.menus[_vm.activeTab]),function(m,i){return (!m.mainData)?_c('div',{key:`menu-${i}`,staticClass:"menu-item hover-pointer",class:{
            active: _vm.activeMenu == i,
          },on:{"click":function($event){_vm.activeMenu = i}}},[_c('div',{staticClass:"d-flex flex-column"},[_c('h6',[_vm._v(_vm._s(m.label))]),_c('p',{staticClass:"mb-0 value"},[_vm._v(_vm._s(m.description))])]),_c('v-icon',[_vm._v("mdi-chevron-right")])],1):_vm._e()}),0)],1)],1),_c('v-col',{attrs:{"md":"8","xl":"9"}},[_c('v-card',{staticClass:"rra-pra-detail-card mb-5",attrs:{"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800"}},[_c('div',{staticClass:"segment-menu"},_vm._l((_vm.tabs),function(item,i){return _c('button',{key:'segment-' + i,class:{
            active: i === _vm.activeTab,
          },on:{"click":function($event){_vm.activeMenu = i == 0 ? 1 : 1;
            _vm.activeTab = i;}}},[_c('v-icon',[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.label)+" ")],1)}),0),_c('v-card-title',[_c('h5',{staticClass:"mb-0 pb-0"},[_vm._v(_vm._s(_vm.tabs[_vm.activeTab].label))])]),(_vm.data.rra)?_c('div',{staticClass:"detail-wrapper"},[_c('rra-pra-detail-rra',{attrs:{"data":_vm.data ? (_vm.activeTab == 0 ? _vm.data.rra : _vm.data.pra) : null,"scoopingData":_vm.data ? _vm.data.mainScooping : null,"activeMenu":0,"activeTab":_vm.activeTab,"rraCode":_vm.data.rra && _vm.data.rra.mainRra && _vm.data.rra.mainRra.rra_no}})],1):_vm._e()],1),_c('v-card',{staticClass:"rra-pra-detail-card mb-5",attrs:{"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800","componentKey":`comp-detail-${_vm.componentKey}`}},[_c('v-card-title',[_c('h5',{staticClass:"mb-0 pb-0"},[_vm._v(" "+_vm._s(_vm.menus[_vm.activeTab][_vm.activeMenu].label)+" ")])]),_c('div',{staticClass:"detail-wrapper"},[(
            _vm.activeTab == 0 || (_vm.activeTab == 1 && _vm.data.pra && _vm.data.pra.mainPra)
          )?_c('rra-pra-detail-rra',{attrs:{"data":_vm.data ? (_vm.activeTab == 0 ? _vm.data.rra : _vm.data.pra) : null,"scoopingData":_vm.data ? _vm.data.mainScooping : null,"activeMenu":_vm.activeMenu,"activeTab":_vm.activeTab}}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }