var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"50%"},scopedSlots:_vm._u([{key:"default",fn:function({ isActive }){return [_c('v-card',[_c('v-card-title',{staticClass:"text-center"},[_vm._v(" Verifikasi ")]),_c('v-card-text',[_c('ValidationObserver',{ref:"firstForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',{staticClass:"scooping-visit-gis-verification"},[_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
                  label: 'Status Potensial',
                  validation: ['required'],
                  type: 'select',
                  option: {
                    default_options: [
                      // {
                      //   text: 'Berpotensi',
                      //   value: 1,
                      // },
                      // {
                      //   text: 'Tidak berpotensi',
                      //   value: 0,
                      // },
                      {
                        text: 'Potensial Carbon',
                        value: 2,
                      },
                      {
                        text: 'Potensial Non Carbon',
                        value: 3,
                      },
                      {
                        text: 'Potensial Carbon & Non Carbon',
                        value: 4,
                      },
                    ],
                    list_pointer: {
                      code: 'value',
                      label: 'text',
                      display: ['text'],
                    },
                  },
                }},model:{value:(_vm.potential_status),callback:function ($$v) {_vm.potential_status=$$v},expression:"potential_status"}})],1),_c('v-col',{staticClass:"d-flex flex-row justify-content-end",attrs:{"md":"12"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"variant":"light"},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" Batal ")]),_c('v-btn',{attrs:{"type":"submit","disabled":_vm.loading || !_vm.ready,"variant":"success"}},[_vm._v(" Verifikasi ")])],1)],1)],1)]}}],null,true)})],1)],1)]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}})
}
var staticRenderFns = []

export { render, staticRenderFns }