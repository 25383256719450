var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"geko-form-card mb-5",attrs:{"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("mdi-arrow-left-circle")]),_c('h4',{staticClass:"mb-0 pb-0"},[_vm._v(" "+_vm._s(_vm.isCreate ? "Tambah" : "Perbarui")+" "+_vm._s(_vm.title)+" ")])],1),_vm._t("create-form",function(){return [_c('ValidationObserver',{ref:"mainForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [(_vm.ready)?_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',{staticClass:"geko-form-wrapper"},[_vm._l((!_vm.sort
              ? _vm.fields.filter((x) => x.input)
              : _vm.fields.filter((x) => x.input).sort(_vm.sortForm)),function(item,i){return (
              item.show_if
                ? _vm.formData[item.show_if.id] == item.show_if.value
                : true
            )?[_vm._t(_vm.$route.query.view === 'create'
                  ? 'create' + '-' + item.view_data
                  : 'update' + '-' + item.view_data,function(){return [(item.separator)?_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v(_vm._s(item.separator))])]):_vm._e(),_c('v-col',{attrs:{"md":item.col_size}},[_c('geko-input',{attrs:{"item":{
                    label: item.label,
                    type: item.type,
                    validation: item.validation,
                    option: item.option,
                    api: item.getter,
                    param: item.param,
                    default_label: item.option
                      ? _vm.formData[item.option.default_label]
                      : '',
                  },"disabled":item.disabled || false},model:{value:(_vm.formData[item.setter]),callback:function ($$v) {_vm.$set(_vm.formData, item.setter, $$v)},expression:"formData[item.setter]"}})],1)]},{"formData":_vm.formData,"setFormData":_vm.setFormData,"field":item})]:_vm._e()}),_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"form-footer"},[_c('v-btn',{staticClass:"mr-3",attrs:{"variant":"light"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Batal ")]),_c('v-btn',{attrs:{"variant":_vm.isCreate ? 'success' : 'warning',"type":"submit","disabled":_vm.loading}},[(_vm.isCreate && !_vm.loading)?_c('v-icon',[_vm._v("mdi-plus")]):_vm._e(),(!_vm.isCreate && !_vm.loading)?_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-pencil-minus")]):_vm._e(),(_vm.loading)?_c('v-progress-circular',{staticClass:"mr-1",attrs:{"color":_vm.isCreate ? 'green' : 'amber',"indeterminate":"","width":2,"size":15}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.isCreate ? "Tambah" : "Perbarui")+" Data")])],1)],1)])],2)],1):_vm._e()]}}],null,true)})]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }