var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rra-pra-form"},[_c('ValidationObserver',{ref:"firstForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"form-stepper-header"},[_c('div',{staticClass:"form-stepper-header-item"},[_c('span',{staticClass:"value"},[_vm._v("1")]),_c('span',{staticClass:"label"},[_vm._v("RRA")])]),_c('div',{staticClass:"form-stepper-header-splitter"},[_c('span')]),_c('div',{staticClass:"form-stepper-header-item",class:{
              active: true,
            }},[_c('span',{staticClass:"value"},[_vm._v("2")]),_c('span',{staticClass:"label"},[_vm._v("PRA")])])])])],1),_c('v-row',[_c('v-col',{staticClass:"form-separator d-flex flex-row",staticStyle:{"align-items":"center"},attrs:{"md":"12"}},[_c('h4',[_vm._v("Data Scooping & RRA")])]),_c('v-col',{attrs:{"lg":"5"}},[_c('geko-input',{attrs:{"item":{
            type: 'text',
            validation: ['required'],
            label: 'No. Scooping Visit',
          },"disabled":true},model:{value:(_vm.$route.query.scooping_visit_code),callback:function ($$v) {_vm.$set(_vm.$route.query, "scooping_visit_code", $$v)},expression:"$route.query.scooping_visit_code"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
            type: 'text',
            validation: ['required'],
            label: 'No. RRA',
          },"disabled":true},model:{value:(_vm.$route.query.rra_code),callback:function ($$v) {_vm.$set(_vm.$route.query, "rra_code", $$v)},expression:"$route.query.rra_code"}})],1),_c('v-col',{attrs:{"lg":"5"}},[_c('geko-input',{attrs:{"item":{
            type: 'text',
            validation: ['required'],
            label: 'Desa ',
          },"disabled":true},model:{value:(_vm.$route.query.scooping_visit_village),callback:function ($$v) {_vm.$set(_vm.$route.query, "scooping_visit_village", $$v)},expression:"$route.query.scooping_visit_village"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
            type: 'text',
            label: 'Tanggal Scooping',
          },"disabled":true,"value":`${_vm.formatDate(
            _vm.$route.query.scooping_visit_start
          )} - ${_vm.formatDate(_vm.$route.query.scooping_visit_end)}`}})],1),_vm._l((_vm.config),function(parent,i){return [_c('v-col',{staticClass:"form-separator d-flex flex-row",staticStyle:{"align-items":"center"},attrs:{"md":"12"}},[_c('h4',[_vm._v(_vm._s(parent.name))]),(
              (parent.fieldData !== 'farmerIncomes' ||
                (parent.fieldData === 'farmerIncomes' &&
                  _vm.formData.collection_type === 'Sampling')) &&
              parent.fieldData
            )?_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","variant":"success"},on:{"click":function($event){return _vm.addRow(parent.fieldData)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1):_vm._e()],1),(
            (Array.isArray(_vm.formFieldData[parent.fieldData]) &&
              _vm.formFieldData[parent.fieldData].length > 0) ||
            !parent.fieldData
          )?_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"bg-grey"},[(
                (Array.isArray(parent.fields) &&
                  parent.fields.filter(
                    (x) => x.main_form && x.pre_main_form
                  ).length > 0) ||
                !parent.fieldData
              )?_c('v-row',_vm._l((parent.fields.filter(
                  (x) => x.main_form && x.pre_main_form
                )),function(input,k){return _c('v-col',{key:`f-${i}-${k}`,attrs:{"md":input.size}},[_c('geko-input',{attrs:{"item":{
                    label: input.name,
                    validation: input.validation || [],
                    type: input.type || 'text',
                    api: input.api || '',
                    getterKey: input.getterKey,
                    option: ['select', 'select-radio'].includes(input.type)
                      ? {
                        default_options: input.options || null,
                        multiple: input.multiple,
                        list_pointer: {
                          code: input.code ? input.code : 'code',
                          label: input.label ? input.label : 'name',
                          display: [input.label ? input.label : 'name'],
                        },
                      }
                      : null,
                  }},model:{value:(_vm.formData[input.setter]),callback:function ($$v) {_vm.$set(_vm.formData, input.setter, $$v)},expression:"formData[input.setter]"}})],1)}),1):_vm._e(),_vm._l((_vm.formFieldData[parent.fieldData]),function(f,j){return (parent.fields.filter((x) => !x.main_form).length > 0)?_c('v-row',{key:`f-${i}-${j}`},[_vm._l((parent.fields.filter(
                  (x) => !x.main_form
                )),function(input,k){return (
                  (!input.show_if && !input.item_show_if) ||
                  (input.show_if &&
                    _vm.formData[input.show_if] == input.show_if_equals) ||
                  (input.item_show_if &&
                    f[input.item_show_if] == input.item_show_if_equals)
                )?[_c('v-col',{key:`f-${i}-${j}-${k}`,attrs:{"md":input.size}},[(input.type === 'delete')?_c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%","justify-content":"center"}},[(
                        _vm.formFieldData[parent.fieldData].length >
                        (parent.allowEmpty ? 0 : 1)
                      )?_c('button',{staticClass:"text-danger",on:{"click":function($event){return _vm.removeRow(parent.fieldData, j)}}},[_c('v-icon',{staticClass:"text-danger"},[_vm._v("mdi-close")])],1):_vm._e()]):_c('geko-input',{attrs:{"item":{
                      label: input.name,
                      validation: input.validation || [],
                      type: input.type || 'text',
                      api: input.api || '',
                      option: ['select', 'select-radio'].includes(
                        input.type
                      )
                        ? {
                          default_options: input.options || null,
                          multiple: input.multiple,
                          getterKey: input.getterKey,
                          list_pointer: {
                            code: input.code ? input.code : 'code',
                            label: input.label ? input.label : 'name',
                            display: [input.label ? input.label : 'name'],
                          },
                        }
                        : null,
                    }},model:{value:(f[input.setter]),callback:function ($$v) {_vm.$set(f, input.setter, $$v)},expression:"f[input.setter]"}})],1)]:_vm._e()})],2):_vm._e()}),(
                Array.isArray(parent.fields) &&
                parent.fields.filter((x) => x.main_form && !x.pre_main_form)
                  .length > 0
              )?_c('v-row',_vm._l((parent.fields.filter(
                  (x) => x.main_form && !x.pre_main_form
                )),function(input,k){return (
                  !input.show_if ||
                  (input.show_if &&
                    _vm.formData[input.show_if] == input.show_if_equals)
                )?_c('v-col',{class:{
                    'm-0 p-0': input.type === 'group',
                  },attrs:{"md":input.size}},[(input.type === 'group')?_c('div',[_c('h5',[_vm._v(_vm._s(input.name))])]):_c('geko-input',{attrs:{"item":{
                    label: input.name,
                    validation: input.validation || [],
                    type: input.type || 'text',
                    api: input.api || '',
                    option: ['select', 'select-radio'].includes(input.type)
                      ? {
                        default_options: input.options || null,
                        multiple: input.multiple,
                        getterKey: input.getterKey,
                        list_pointer: {
                          code: input.code ? input.code : 'code',
                          label: input.label ? input.label : 'name',
                          display: [input.label ? input.label : 'name'],
                        },
                      }
                      : null,
                  }},model:{value:(_vm.formData[input.setter]),callback:function ($$v) {_vm.$set(_vm.formData, input.setter, $$v)},expression:"formData[input.setter]"}})],1):_vm._e()}),1):_vm._e()],2)]):_vm._e()]})],2),_c('v-col',{staticClass:"form-separator d-flex flex-column",attrs:{"md":"12"}},[_c('h4',[_vm._v("Matriks Permasalahan")]),_c('p',{staticClass:"text-grey text-09-em"},[_vm._v(" NB: Angka terkecil adalah yang paling bermasalah ")])]),_c('v-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"bg-grey"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"problem-matrix"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Nama Masalah")]),_c('th',[_vm._v("Dirasakan")]),_c('th',[_vm._v("Sering Terjadi")]),_c('th',[_vm._v("Prioritas")]),_c('th',[_vm._v("Potensi")]),_c('th',[_vm._v("Jumlah")]),_c('th',[_vm._v("Ranking")])])]),_c('tbody',_vm._l((_vm.formFieldData.existingProblems),function(item,i){return _c('tr',{key:`index-${i}`},[_c('td',{staticClass:"text-center"},[(item.problem_name)?_c('span',[_vm._v(_vm._s(item.problem_name))]):_c('span',{staticClass:"text-italic text-09-em text-grey"},[_vm._v("Isi nama masalah terlebih dahulu")])]),_c('td',[_c('div',{staticClass:"problem-matrix-wrapper"},_vm._l((_vm.formFieldData.existingProblems),function(child,j){return _c('button',{class:{
                          'problem-matrix-btn': true,
                          active: item.impact_to_people == j + 1,
                          disabled:
                            _vm.formFieldData.existingProblems.find(
                              (x) => x.impact_to_people == j + 1
                            ) &&
                            _vm.formFieldData.existingProblems.findIndex(
                              (x) => x.impact_to_people == j + 1
                            ) !== i,
                        },attrs:{"disabled":_vm.formFieldData.existingProblems.find(
                          (x) => x.impact_to_people == j + 1
                        ) &&
                          _vm.formFieldData.existingProblems.findIndex(
                            (x) => x.impact_to_people == j + 1
                          ) !== i},on:{"click":function($event){item.impact_to_people =
                        j + 1 == item.impact_to_people ? null : j + 1}}},[_vm._v(" "+_vm._s(j + 1)+" ")])}),0)]),_c('td',[_c('div',{staticClass:"problem-matrix-wrapper"},_vm._l((_vm.formFieldData.existingProblems),function(child,j){return _c('button',{class:{
                          'problem-matrix-btn': true,
                          active: item.interval_problem == j + 1,
                          disabled:
                            _vm.formFieldData.existingProblems.find(
                              (x) => x.interval_problem == j + 1
                            ) &&
                            _vm.formFieldData.existingProblems.findIndex(
                              (x) => x.interval_problem == j + 1
                            ) !== i,
                        },attrs:{"disabled":_vm.formFieldData.existingProblems.find(
                          (x) => x.interval_problem == j + 1
                        ) &&
                          _vm.formFieldData.existingProblems.findIndex(
                            (x) => x.interval_problem == j + 1
                          ) !== i},on:{"click":function($event){item.interval_problem =
                        j + 1 == item.interval_problem ? null : j + 1}}},[_vm._v(" "+_vm._s(j + 1)+" ")])}),0)]),_c('td',[_c('div',{staticClass:"problem-matrix-wrapper"},_vm._l((_vm.formFieldData.existingProblems),function(child,j){return _c('button',{class:{
                          'problem-matrix-btn': true,
                          active: item.priority == j + 1,
                          disabled:
                            _vm.formFieldData.existingProblems.find(
                              (x) => x.priority == j + 1
                            ) &&
                            _vm.formFieldData.existingProblems.findIndex(
                              (x) => x.priority == j + 1
                            ) !== i,
                        },attrs:{"disabled":_vm.formFieldData.existingProblems.find(
                          (x) => x.priority == j + 1
                        ) &&
                          _vm.formFieldData.existingProblems.findIndex(
                            (x) => x.priority == j + 1
                          ) !== i},on:{"click":function($event){item.priority =
                        j + 1 == item.priority ? null : j + 1}}},[_vm._v(" "+_vm._s(j + 1)+" ")])}),0)]),_c('td',[_c('div',{staticClass:"problem-matrix-wrapper"},_vm._l((_vm.formFieldData.existingProblems),function(child,j){return _c('button',{class:{
                          'problem-matrix-btn': true,
                          active: item.potential == j + 1,
                          disabled:
                            _vm.formFieldData.existingProblems.find(
                              (x) => x.potential == j + 1
                            ) &&
                            _vm.formFieldData.existingProblems.findIndex(
                              (x) => x.potential == j + 1
                            ) !== i,
                        },attrs:{"disabled":_vm.formFieldData.existingProblems.find(
                          (x) => x.potential == j + 1
                        ) &&
                          _vm.formFieldData.existingProblems.findIndex(
                            (x) => x.potential == j + 1
                          ) !== i},on:{"click":function($event){item.potential =
                        j + 1 == item.potential ? null : j + 1}}},[_vm._v(" "+_vm._s(j + 1)+" ")])}),0)]),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s((item.impact_to_people || 0) + (item.interval_problem || 0) + (item.potential || 0) + (item.priority || 0)))])]),_c('td',[_c('div',{staticClass:"problem-matrix-wrapper"},_vm._l((_vm.formFieldData.existingProblems),function(child,j){return _c('button',{class:{
                          'problem-matrix-btn': true,
                          active: item.ranking == j + 1,
                          disabled:
                            _vm.formFieldData.existingProblems.find(
                              (x) => x.ranking == j + 1
                            ) &&
                            _vm.formFieldData.existingProblems.findIndex(
                              (x) => x.ranking == j + 1
                            ) !== i,
                        },attrs:{"disabled":_vm.formFieldData.existingProblems.find(
                          (x) => x.ranking == j + 1
                        ) &&
                          _vm.formFieldData.existingProblems.findIndex(
                            (x) => x.ranking == j + 1
                          ) !== i},on:{"click":function($event){item.ranking = j + 1 == item.ranking ? null : j + 1}}},[_vm._v(" "+_vm._s(j + 1)+" ")])}),0)])])}),0)])])])]),_c('v-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"justify-content":"flex-end"}},[_c('v-btn',{attrs:{"type":"submit","variant":"success","disabled":_vm.loading},on:{"click":_vm.onSubmit}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',[_vm._v("Tambah Data PRA")])],1)],1)])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }