export default {
  version: "2.5.0",
  baseUrl: "https://t4tapi.kolaborasikproject.com/api/",
  baseUrlUpload: "https://t4tadmin.kolaborasikproject.com",
  baseUrlExport: "https://geko-export.t4t-api.org/",
  baseUrlNursery: "https://api-nursery.t4t-api.org/api",
  mapBoxApi:
    "pk.eyJ1IjoiZGV2dDR0IiwiYSI6ImNtMm1zc3M0NDBxeXcyanFzd25ndnlva3cifQ.pBINatrH39ajJ_ILIHoGsg",
  mapBoxStyle: "mapbox://styles/mapbox/satellite-streets-v12",
  programYear: {
    model: "2024",
    options: ["2020", "2021", "2022", "2023", "2024"],
  },
};
