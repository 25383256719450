var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.config && Array.isArray(_vm.config.fields))?_c('div',{class:{
    [_vm.wc]: true,
  },staticStyle:{"overflow-x":"scroll"}},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_vm._l((_vm.config.fields),function(h,i){return _c('th',{key:`header-${h.getter}-${i}`,class:{
            [h.header_class || '']: true,
          }},[_vm._v(" "+_vm._s(h.label)+" ")])})],2)]),(Array.isArray(_vm.data))?_c('tbody',_vm._l((_vm.data),function(d,i){return _c('tr',{key:`body-${i}-${d.id}`},_vm._l(([{ id: 1 }, ..._vm.config.fields]),function(f,j){return _c('td',{key:`body-${f.getter}-${i}-${j}`,class:{
            [f.wc || '']: true,
          }},[(j == 0)?_c('span',[_vm._v(_vm._s(i + 1))]):_c('span',[(f.getter == 'custom_dusun')?_c('div',{staticClass:"w-100 d-flex flex-row justify-content-center"},[(d.potential == 1)?_c('v-btn',{staticClass:"d-flex flex-row align-items-center w-100 py-2",attrs:{"variant":"info","medium":""},on:{"click":function($event){return _vm.$emit('detailDusun', d)}}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-information-outline")]),_c('span',{staticClass:"ml-1"},[_vm._v("Detail Dusun")])],1):_c('span',[_vm._v("-")])],1):(f.translate)?_c('span',[_vm._v(_vm._s(_vm._f("parse")(_vm.defaultData[f.translate].find((x) => x.code == [d[f.getter]]) ?.name,f.transform || "no-empty")))]):_c('span',{class:{
                [typeof f.class === 'string' ? f.class : '' || '']: true,
                [typeof f.class === 'object'
                  ? f.class[d[f.getter]]
                  : '']: true,
              }},[_vm._v(_vm._s(_vm._f("parse")(d[f.getter],f.transform || "no-empty"))+" ")]),(f.append)?_c('span',[_vm._v(_vm._s(f.append))]):_vm._e()])])}),0)}),0):_vm._e()]),(_vm.description)?_c('div',{staticClass:"d-flex flex-column detail-table-description mt-3"},[_c('div',{staticClass:"label text-08-em mb-1 text-grey"},[_vm._v(" "+_vm._s(_vm.config.description.label)+" ")]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.description)+" ")])]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }