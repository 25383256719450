var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.ready)?_c('div',{staticClass:"geko-form-wrapper scooping-visit-form"},[_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"form-stepper-header"},[_c('div',{staticClass:"form-stepper-header-item",class:{
          active: _vm.form === 1,
        }},[_c('span',{staticClass:"value"},[_vm._v("1")]),_c('span',{staticClass:"label"},[_vm._v("Tanggal & Data Desa")])]),_c('div',{staticClass:"form-stepper-header-splitter"},[_c('span')]),_c('div',{staticClass:"form-stepper-header-item",class:{
          active: _vm.form === 2,
        }},[_c('span',{staticClass:"value"},[_vm._v("2")]),_c('span',{staticClass:"label"},[_vm._v("Data Wilayah")])]),_c('div',{staticClass:"form-stepper-header-splitter"},[_c('span')]),_c('div',{staticClass:"form-stepper-header-item",class:{
          active: _vm.form === 3,
        }},[_c('span',{staticClass:"value"},[_vm._v("3")]),_c('span',{staticClass:"label"},[_vm._v("Data Lahan, GIS & Tokoh Desa")])])])])],1),_c('ValidationObserver',{directives:[{name:"show",rawName:"v-show",value:(_vm.form === 1),expression:"form === 1"}],ref:"firstForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',[_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("Lokasi & Tanggal Kegiatan Scooping")])]),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            label: 'Provinsi',
            validation: ['required'],
            col_size: 6,
            type: 'select',
            setter: 'province_id',
            api: 'GetProvince',
            default_label: _vm.formData.province_name,
            option: {
              getterKey: 'data.result',
              list_pointer: {
                code: 'id',
                label: 'name',
                display: ['name'],
              },
            },
          }},on:{"selected":function($event){return _vm.onChangeProvince($event)}},model:{value:(_vm.formData.province_id),callback:function ($$v) {_vm.$set(_vm.formData, "province_id", $$v)},expression:"formData.province_id"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            label: 'Kabupaten / Kota',
            validation: ['required'],
            col_size: 6,
            type: 'select',
            setter: 'city_id',
            api: 'GetKabupaten',
            param: {
              province_code: _vm.formData.province_code,
            },
            default_label: _vm.formData.city_name,
            option: {
              getterKey: 'data.result',
              list_pointer: {
                code: 'id',
                label: 'name',
                display: ['name'],
              },
            },
          },"disabled":!_vm.formData.province_id},on:{"selected":function($event){_vm.formData.city_code = $event.kabupaten_no}},model:{value:(_vm.formData.city_id),callback:function ($$v) {_vm.$set(_vm.formData, "city_id", $$v)},expression:"formData.city_id"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            label: 'Kecamatan',
            validation: ['required'],
            col_size: 6,
            type: 'select',
            setter: 'district_id',
            api: 'GetKecamatan',
            param: {
              kabupaten_no: _vm.formData.city_code,
            },
            default_label: _vm.formData.district_name,
            option: {
              getterKey: 'data.result',
              list_pointer: {
                code: 'id',
                label: 'name',
                display: ['name'],
              },
            },
          },"disabled":!_vm.formData.city_id},on:{"selected":function($event){_vm.formData.district_code = $event.kode_kecamatan}},model:{value:(_vm.formData.district_id),callback:function ($$v) {_vm.$set(_vm.formData, "district_id", $$v)},expression:"formData.district_id"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            label: 'Desa',
            validation: ['required'],
            col_size: 6,
            type: 'select',
            setter: 'village_id',
            api: 'GetDesa',
            param: {
              kode_kecamatan: _vm.formData.district_code,
            },
            default_label: _vm.formData.village_name,
            option: {
              getterKey: 'data.result',
              list_pointer: {
                code: 'id',
                label: 'name',
                display: ['name'],
              },
            },
          },"disabled":!_vm.formData.district_id},on:{"selected":function($event){_vm.formData.village_code = $event.kode_desa}},model:{value:(_vm.formData.village_id),callback:function ($$v) {_vm.$set(_vm.formData, "village_id", $$v)},expression:"formData.village_id"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            label: 'Project',
            validation: ['required'],
            col_size: 6,
            type: 'select',
            setter: 'project_id',
            api: 'GetProjectAllAdmin',
            default_label: _vm.formData.project_code,
            param: {
              limit: 1000,
            },
            option: {
              multiple: true,
              list_pointer: {
                separator: '-',
                code: 'id',
                label: 'project_name',
                display: ['project_name', 'project_no'],
              },
            },
          }},model:{value:(_vm.formData.project_id),callback:function ($$v) {_vm.$set(_vm.formData, "project_id", $$v)},expression:"formData.project_id"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            label: 'Tanggal Scooping',
            validation: ['required'],
            col_size: 6,
            type: 'daterange',
            setter: 'date',
          }},model:{value:(_vm.formData.date),callback:function ($$v) {_vm.$set(_vm.formData, "date", $$v)},expression:"formData.date"}})],1),_c('v-col',{staticStyle:{"padding-left":"1.5em"},attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
              view_data: 'potential_status',
              type: 'select-radio',
              label:
                'Status Potensial',
              validation: ['required'],
              option: {
                list_pointer: {
                  label: 'label',
                  code: 'code',
                  display: ['label'],
                },
                default_options: [
                  {
                    label: 'Potensial Carbon',
                    code: 2,
                  },
                  {
                    label: 'Potensial Non Carbon',
                    code: 3,
                  },
                  {
                    label: 'Potensial Carbon & Non Carbon',
                    code: 4,
                  }
                ],
              },
            }},model:{value:(_vm.formData.potential_status),callback:function ($$v) {_vm.$set(_vm.formData, "potential_status", $$v)},expression:"formData.potential_status"}})],1),_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("Data General Desa")])]),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            label: 'Luas Desa (Ha)',
            validation: ['required'],
            col_size: 6,
            type: 'number',
          }},model:{value:(_vm.formData.village_area),callback:function ($$v) {_vm.$set(_vm.formData, "village_area", $$v)},expression:"formData.village_area"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            label: 'Akesibilitas',
            validation: ['required'],
            col_size: 6,
            type: 'select',
            option: {
              default_options: _vm.defaultData.accessibility,
              list_pointer: {
                code: 'value',
                label: 'text',
                display: ['text'],
              },
            },
          }},model:{value:(_vm.formData.accessibility),callback:function ($$v) {_vm.$set(_vm.formData, "accessibility", $$v)},expression:"formData.accessibility"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            label: 'Sumber Air',
            validation: ['required'],
            col_size: 6,
            type: 'select',
            option: {
              multiple: true,
              default_options: _vm.defaultData.water_source,
              list_pointer: {
                code: 'value',
                label: 'text',
                display: ['text'],
              },
            },
          }},model:{value:(_vm.formData.water_source),callback:function ($$v) {_vm.$set(_vm.formData, "water_source", $$v)},expression:"formData.water_source"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            label: 'Sumber Listrik',
            validation: ['required'],
            col_size: 6,
            type: 'select',
            option: {
              multiple: true,
              default_options: _vm.defaultData.electricity_source,
              list_pointer: {
                code: 'value',
                label: 'text',
                display: ['text'],
              },
            },
          }},model:{value:(_vm.formData.electricity_source),callback:function ($$v) {_vm.$set(_vm.formData, "electricity_source", $$v)},expression:"formData.electricity_source"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            label: 'Tempat Pemerintahan',
            validation: ['required'],
            col_size: 6,
            type: 'select',
            option: {
              multiple: true,
              default_options: _vm.defaultData.goverment_place,
              list_pointer: {
                code: 'value',
                label: 'text',
                display: ['text'],
              },
            },
          }},model:{value:(_vm.formData.goverment_place),callback:function ($$v) {_vm.$set(_vm.formData, "goverment_place", $$v)},expression:"formData.goverment_place"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            label: 'Cakupan Lahan',
            validation: ['required'],
            col_size: 6,
            type: 'select',
            option: {
              multiple: true,
              default_options: _vm.defaultData.land_coverage,
              list_pointer: {
                code: 'value',
                label: 'text',
                display: ['text'],
              },
            },
          }},model:{value:(_vm.formData.land_coverage),callback:function ($$v) {_vm.$set(_vm.formData, "land_coverage", $$v)},expression:"formData.land_coverage"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            label: 'Kerapatan Vegetasi',
            validation: ['required'],
            col_size: 6,
            type: 'select',
            option: {
              multiple: true,
              default_options: _vm.defaultData.vegetation_density,
              list_pointer: {
                code: 'value',
                label: 'text',
                display: ['text'],
              },
            },
          }},model:{value:(_vm.formData.vegetation_density),callback:function ($$v) {_vm.$set(_vm.formData, "vegetation_density", $$v)},expression:"formData.vegetation_density"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            label: 'Pola Tanam Lahan Kering',
            validation: ['required'],
            col_size: 6,
            type: 'select',
            option: {
              multiple: true,
              default_options: _vm.defaultData.agroforestry_type,
              list_pointer: {
                code: 'value',
                label: 'text',
                display: ['text'],
              },
            },
          }},model:{value:(_vm.formData.agroforestry_type),callback:function ($$v) {_vm.$set(_vm.formData, "agroforestry_type", $$v)},expression:"formData.agroforestry_type"}})],1),_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex flex-row justify-content-end",staticStyle:{"justify-content":"flex-end"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"variant":"light"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Batal")]),_c('v-btn',{attrs:{"type":"submit","variant":"success","disabled":_vm.loading}},[_c('v-icon',[_vm._v("mdi-chevron-right")]),_c('span',[_vm._v("Selanjutnya")])],1)],1)])],1)],1)]}}],null,false,2666126643)}),_c('ValidationObserver',{directives:[{name:"show",rawName:"v-show",value:(_vm.form === 2),expression:"form === 2"}],ref:"secondForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',[_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("Data Populasi & Wilayah")])]),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            label: 'Total Dusun',
            validation: ['required'],
            type: 'number',
            setter: 'total_dusun',
            view_data: 'total_dusun',
          }},model:{value:(_vm.formData.total_dusun),callback:function ($$v) {_vm.$set(_vm.formData, "total_dusun", $$v)},expression:"formData.total_dusun"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            label: 'Potential Dusun',
            validation: ['required'],
            type: 'number',
            setter: 'potential_dusun',
          }},model:{value:(_vm.formData.potential_dusun),callback:function ($$v) {_vm.$set(_vm.formData, "potential_dusun", $$v)},expression:"formData.potential_dusun"}})],1),_c('v-col',{attrs:{"md":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Potential Dusun',
            placeholder: 'Deskripsi / alasan dusun berpotensi',
            validation: ['required'],
            type: 'editor',
            setter: 'potential_description',
          }},model:{value:(_vm.formData.potential_description),callback:function ($$v) {_vm.$set(_vm.formData, "potential_description", $$v)},expression:"formData.potential_description"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('geko-input',{attrs:{"item":{
            label: 'Jumlah Laki-Laki',
            validation: ['required'],
            type: 'number',
            setter: 'total_male',
          }},model:{value:(_vm.formData.total_male),callback:function ($$v) {_vm.$set(_vm.formData, "total_male", $$v)},expression:"formData.total_male"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('geko-input',{attrs:{"item":{
            label: 'Jumlah Perempuan',
            validation: ['required'],
            type: 'number',
            setter: 'total_female',
          }},model:{value:(_vm.formData.total_female),callback:function ($$v) {_vm.$set(_vm.formData, "total_female", $$v)},expression:"formData.total_female"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('geko-input',{attrs:{"item":{
            label: 'Jumlah Keluarga (KK)',
            validation: ['required'],
            type: 'number',
            setter: 'total_kk',
          }},model:{value:(_vm.formData.total_kk),callback:function ($$v) {_vm.$set(_vm.formData, "total_kk", $$v)},expression:"formData.total_kk"}})],1),_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("Upload Foto Dokumentasi Kegiatan")])]),_c('v-col',{attrs:{"md":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Foto Akses Jalan',
            validation: ['required'],
            type: 'upload',
            api: 'scooping_visits/upload.php',
            directory: 'photos',
            upload_type: 'image/*',
            setter: 'photo_road_access',
            view_data: 'photo_road_access',
            option: {
              label_hint:
                'Klik gambar untuk memilih berkas yang akan diunggah',
              max_size: 5,
              max: 5,
              multiple: true,
            },
          }},model:{value:(_vm.formData.photo_road_access),callback:function ($$v) {_vm.$set(_vm.formData, "photo_road_access", $$v)},expression:"formData.photo_road_access"}})],1),_c('v-col',{attrs:{"md":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Foto Pertemuan Dengan Tokoh Desa',
            validation: ['required'],
            type: 'upload',
            setter: 'photo_meeting',
            view_data: 'photo_meeting',
            upload_type: 'image/*',
            api: 'scooping_visits/upload.php',
            directory: 'photos',
            option: {
              label_hint:
                'Klik gambar untuk memilih berkas yang akan diunggah',
              max_size: 5,
              max: 3,
              multiple: true,
            },
          }},model:{value:(_vm.formData.photo_meeting),callback:function ($$v) {_vm.$set(_vm.formData, "photo_meeting", $$v)},expression:"formData.photo_meeting"}})],1),_c('v-col',{attrs:{"md":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Foto Lahan Kering',
            validation: ['required'],
            type: 'upload',
            setter: 'photo_dry_land',
            view_data: 'photo_dry_land',
            api: 'scooping_visits/upload.php',
            directory: 'photos',
            upload_type: 'image/*',
            option: {
              label_hint:
                'Klik gambar untuk memilih berkas yang akan diunggah',
              max_size: 5,
              max: 5,
              multiple: true,
            },
          }},model:{value:(_vm.formData.photo_dry_land),callback:function ($$v) {_vm.$set(_vm.formData, "photo_dry_land", $$v)},expression:"formData.photo_dry_land"}})],1),_c('v-col',{attrs:{"md":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Profile Desa',
            validation: ['required'],
            type: 'upload',
            setter: 'village_profile',
            view_data: 'village_profile',
            api: 'scooping_visits/upload.php',
            directory: 'photos',
            upload_type: 'image/*',
            option: {
              label_hint:
                'Klik gambar untuk memilih berkas yang akan diunggah',
              max_size: 5,
            },
          }},model:{value:(_vm.formData.village_profile),callback:function ($$v) {_vm.$set(_vm.formData, "village_profile", $$v)},expression:"formData.village_profile"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"d-flex flex-row justify-content-end"},[_c('v-btn',{staticClass:"mr-3",attrs:{"variant":"light","disabled":_vm.loading},on:{"click":function($event){_vm.form = 1}}},[_c('span',[_vm._v("Back")])]),_c('v-btn',{attrs:{"variant":"success","type":"submit","disabled":_vm.loading}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-chevron-right")]),_c('span',[_vm._v("Selanjutnya")])],1)],1)])],1)],1)]}}],null,false,1594643557)}),_c('ValidationObserver',{directives:[{name:"show",rawName:"v-show",value:(_vm.form === 3),expression:"form === 3"}],ref:"thirdForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',[_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("Kelengkapan Data Lahan Kering")])]),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            label: 'Luas Lahan Kering / Kritis',
            validation: ['required'],
            type: 'number',
            setter: 'land_area',
            view_data: 'land_area',
          }},model:{value:(_vm.formData.land_area),callback:function ($$v) {_vm.$set(_vm.formData, "land_area", $$v)},expression:"formData.land_area"}})],1),_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("INFORMASI LAINNYA")])]),_c('v-row',[_c('v-col',{staticStyle:{"padding-left":"1.5em"},attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
              view_data: 'other_ngo',
              type: 'select-radio',
              label:
                'Apakah ada Lembaga lain/NGO lain di desa dengan program Jasa Lingkungan?',
              validation: [],
              option: {
                list_pointer: {
                  label: 'label',
                  code: 'code',
                  display: ['label'],
                },
                default_options: [
                  {
                    label: 'Ada',
                    code: '1',
                  },
                  {
                    label: 'Tidak Ada',
                    code: '0',
                  },
                ],
              },
            }},model:{value:(_vm.formData.other_ngo),callback:function ($$v) {_vm.$set(_vm.formData, "other_ngo", $$v)},expression:"formData.other_ngo"}})],1),(_vm.formData.other_ngo == '1')?_c('v-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"other-ngo d-flex flex-row align-items-end",staticStyle:{"align-items":"flex-end"}},[_c('geko-input',{attrs:{"item":{
                label: 'Nama Lembaga',
                type: 'text',
                validation: [],
              }},model:{value:(_vm.formData.other_ngo_input),callback:function ($$v) {_vm.$set(_vm.formData, "other_ngo_input", $$v)},expression:"formData.other_ngo_input"}}),_c('v-btn',{staticClass:"mb-2 ml-2",attrs:{"variant":"success"},on:{"click":_vm.addOtherNgo}},[_vm._v("Tambah")])],1),_c('div',{staticClass:"ngo-list d-flex flex-row",staticStyle:{"flex-wrap":"wrap"}},_vm._l((_vm.formData.other_ngo_data),function(ngo,i){return _c('div',{staticClass:"badge bg-info mr-1 mb-1",on:{"click":function($event){return _vm.removeOtherNgo(i)}}},[_vm._v(" "+_vm._s(ngo.name)+" ")])}),0)]):_vm._e()],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            view_data: 'mitigation_program',
            type: 'select-radio',
            label:
              'Adakah Program Mitigasi Perubahan Iklim di Desa tersebut?',
            validation: [],
            option: {
              list_pointer: {
                label: 'label',
                code: 'code',
                display: ['label'],
              },
              default_options: [
                {
                  label: 'Ada',
                  code: '1',
                },
                {
                  label: 'Tidak Ada',
                  code: '0',
                },
              ],
            },
          }},model:{value:(_vm.formData.mitigation_program),callback:function ($$v) {_vm.$set(_vm.formData, "mitigation_program", $$v)},expression:"formData.mitigation_program"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            view_data: 'resident_acceptance',
            type: 'textarea',
            label: 'Bagaimana Keberterimaan Masyarakat?',
            validation: [],
          }},model:{value:(_vm.formData.resident_acceptance),callback:function ($$v) {_vm.$set(_vm.formData, "resident_acceptance", $$v)},expression:"formData.resident_acceptance"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            view_data: 'next_event_contact_person',
            type: 'text',
            label: 'Nama PIC Event Selanjutnya',
            validation: [],
          }},model:{value:(_vm.formData.next_event_contact_person),callback:function ($$v) {_vm.$set(_vm.formData, "next_event_contact_person", $$v)},expression:"formData.next_event_contact_person"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            view_data: 'next_event_contact_person_number',
            type: 'text',
            label: 'No HP PIC Event Selanjutnya',
            validation: _vm.formData.next_event_contact_person
              ? ['required', 'phone']
              : ['phone'],
          }},model:{value:(_vm.formData.next_event_contact_person_number),callback:function ($$v) {_vm.$set(_vm.formData, "next_event_contact_person_number", $$v)},expression:"formData.next_event_contact_person_number"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            view_data: 'general_land_condition',
            type: 'textarea',
            label: 'Bagaimana Kondisi Umum Lahan untuk Program T4T?',
            validation: [],
          }},model:{value:(_vm.formData.general_land_condition),callback:function ($$v) {_vm.$set(_vm.formData, "general_land_condition", $$v)},expression:"formData.general_land_condition"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
            view_data: 'field_companion_potency',
            type: 'textarea',
            label: 'Potensi pendamping lapang?',
            validation: [],
          }},model:{value:(_vm.formData.field_companion_potency),callback:function ($$v) {_vm.$set(_vm.formData, "field_companion_potency", $$v)},expression:"formData.field_companion_potency"}})],1),_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("Kandidat FF")])]),_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"village-person-list"},[_vm._l((_vm.formData.ff_candidates),function(person,i){return _c('v-row',{key:'ff-' + i},[_c('v-col',{staticClass:"index-wrapper",attrs:{"md":"1"}},[_c('div',{staticClass:"index"},[_vm._v(_vm._s(i + 1))])]),_c('v-col',{attrs:{"md":"4"}},[_c('geko-input',{attrs:{"item":{
                  label: 'Nama',
                  validation: ['required'],
                  type: 'text',
                }},model:{value:(person.name),callback:function ($$v) {_vm.$set(person, "name", $$v)},expression:"person.name"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('geko-input',{attrs:{"item":{
                  label: 'No Whatsapp',
                  validation: ['required', 'phone'],
                  type: 'text',
                }},model:{value:(person.whatsapp),callback:function ($$v) {_vm.$set(person, "whatsapp", $$v)},expression:"person.whatsapp"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('geko-input',{attrs:{"item":{
                  label: 'No HP',
                  validation: ['required', 'phone'],
                  type: 'text',
                }},model:{value:(person.phone),callback:function ($$v) {_vm.$set(person, "phone", $$v)},expression:"person.phone"}})],1),_c('v-col',{staticClass:"d-flex flex-column justify-content-center",staticStyle:{"justify-content":"center","align-items":"flex-start"},attrs:{"md":"1"}},[(_vm.formData.ff_candidates.length > 0)?_c('button',{on:{"click":function($event){return _vm.removeFfCandidate(i)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1):_vm._e()])],1)}),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-btn',{attrs:{"variant":"primary"},on:{"click":_vm.addFfCandidate}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',[_vm._v("Tambah Kandidat FF")])],1)],1)],1)],2)]),_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("Tokoh Desa")])]),_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"village-person-list"},[_vm._l((_vm.formData.village_persons),function(person,i){return _c('v-row',{key:'persons-' + i},[_c('v-col',{staticClass:"index-wrapper",attrs:{"md":"1"}},[_c('div',{staticClass:"index"},[_vm._v(_vm._s(i + 1))])]),_c('v-col',{attrs:{"md":"3"}},[_c('geko-input',{attrs:{"item":{
                  label: 'Nama',
                  validation: ['required'],
                  type: 'text',
                }},model:{value:(person.name),callback:function ($$v) {_vm.$set(person, "name", $$v)},expression:"person.name"}})],1),_c('v-col',{attrs:{"md":"2"}},[_c('geko-input',{attrs:{"item":{
                  label: 'Jabatan',
                  validation: ['required'],
                  type: 'text',
                }},model:{value:(person.position),callback:function ($$v) {_vm.$set(person, "position", $$v)},expression:"person.position"}})],1),_c('v-col',{attrs:{"md":"2"}},[_c('geko-input',{attrs:{"item":{
                  label: 'No HP',
                  validation: ['required', 'phone'],
                  type: 'text',
                }},model:{value:(person.phone),callback:function ($$v) {_vm.$set(person, "phone", $$v)},expression:"person.phone"}})],1),_c('v-col',{attrs:{"md":"2"}},[_c('geko-input',{attrs:{"item":{
                  label: 'No WA',
                  validation: ['required', 'phone'],
                  type: 'text',
                }},model:{value:(person.whatsapp),callback:function ($$v) {_vm.$set(person, "whatsapp", $$v)},expression:"person.whatsapp"}})],1),_c('v-col',{staticClass:"d-flex flex-column justify-content-center",staticStyle:{"justify-content":"center","align-items":"flex-start"},attrs:{"md":"1"}},[(_vm.formData.village_persons.length > 1)?_c('button',{on:{"click":function($event){return _vm.removeVillagePerson(i)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1):_vm._e()])],1)}),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-btn',{attrs:{"variant":"primary"},on:{"click":_vm.addVillagePerson}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',[_vm._v("Tambah Tokoh Desa")])],1)],1)],1)],2)]),_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"justify-content":"flex-end"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"variant":"light","disabled":_vm.loading},on:{"click":function($event){_vm.form = 2}}},[_vm._v(" Back ")]),_c('v-btn',{attrs:{"type":"submit","variant":"success","disabled":_vm.loading}},[_c('v-icon',[_vm._v("mdi-plus")]),(_vm.isCreate)?_c('span',[_vm._v("Tambah Data Scooping Visit")]):_c('span',[_vm._v("Perbarui Data Scooping Visit")])],1)],1)])],1)],1)]}}],null,false,1243005022)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }