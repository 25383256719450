var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"geko-base-detail-card mb-5",attrs:{"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800"}},[_c('v-card-title',{staticClass:"d-flex flex-row",staticStyle:{"justify-content":"space-between"}},[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"align-items":"center"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("mdi-arrow-left-circle")]),_c('h4',{staticClass:"mb-0 pb-0"},[_vm._v("Detail "+_vm._s(_vm.title))])],1),_vm._t("detail-header-action",null,{"item":_vm.data,"response":_vm.response})],2),_vm._t("detail-body",function(){return [_c('div',{staticClass:"geko-base-detail-wrapper"},[_c('div',{staticClass:"geko-base-detail-list"},[_vm._l((_vm.fields),function(item,i){return [_vm._t(`detail-row-${item.view_data}`,function(){return [(item.type !== 'row-slot')?_c('div',{key:'base-detail' + i,staticClass:"geko-base-detail-item"},[_c('div',{staticClass:"label"},[_c('p',{staticClass:"mb-0 pb-0"},[_vm._v(_vm._s(item.label))])]),_c('div',{staticClass:"value"},[_c('p',{staticClass:"mb-0 pb-0"},[(item.type === 'slot')?_vm._t(`detail-${item.view_data}`,null,{"item":_vm.data}):_vm._e(),(item.type === 'text')?_c('span',{class:{
                        [item.class
                          ? typeof item.class === 'object'
                            ? item.class[_vm.data[item.view_data]]
                            : typeof item.class == 'string'
                            ? `${item.class} ${_vm.data[item.view_data]}`
                            : ''
                          : '']: true,
                      }},[_vm._v(_vm._s(_vm._f("parse")(_vm.data[item.view_data],item.transform)))]):_vm._e()],2)])]):_vm._e()]},{"item":_vm.data,"response":_vm.response})]}),_vm._t("detail-body-after",null,{"response":_vm.response})],2)])]})],2),_vm._t("detail-slave-raw",null,{"data":_vm.response})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }