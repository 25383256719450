var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-breadcrumbs',{staticClass:"breadcrumbsmain",attrs:{"dark":_vm.$store.state.theme == 'dark',"data-aos":"fade-right","items":_vm.breadcrumbs,"divider":">","large":""}}),_c('v-data-table',{class:`${
      _vm.$store.state.theme == 'dark' ? '' : ''
    } rounded-xl elevation-6 mx-3 pa-1`,attrs:{"data-aos":"fade-up","data-aos-delay":"200","multi-sort":"","headers":_vm.table.headers,"items":_vm.dataObject,"search":_vm.table.search,"loading":_vm.table.loading.show,"loading-text":_vm.table.loading.text,"footer-props":{
      itemsPerPageText: 'Jumlah Data Per Halaman',
      itemsPerPageOptions: [10, 25, 40, -1],
      showCurrentPage: true,
      showFirstLastPage: true,
    }},on:{"update:page":($p) => (_vm.table.pagination.current_page = $p),"update:items-per-page":($p) => (_vm.table.pagination.per_page = $p)},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card',{staticClass:"rounded-xl shadow-lg",attrs:{"color":"green"}},[_c('v-row',{staticClass:"ma-0 mx-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"mb-0 white--text justify-center"},[_c('v-icon',{staticClass:"mr-2",staticStyle:{},attrs:{"color":"white"}},[_vm._v("mdi-eye-outline")]),_c('strong',[_vm._v(" Tanggal Distribusi ")])],1),_c('v-divider',{staticClass:"mx-1"})],1)])],1)],1)]},proxy:true},{key:"item.index",fn:function({ index }){return [_vm._v(" "+_vm._s(_vm.table.pagination.per_page * (_vm.table.pagination.current_page - 1) + index + 1)+" ")]}}])}),_c('v-snackbar',{attrs:{"color":_vm.colorsnackbar,"timeout":_vm.timeoutsnackbar},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.textsnackbar)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }