var render = function render(){var _vm=this,_c=_vm._self._c;return _c('geko-base-crud',{key:'scooping-visit-' + _vm.componentKey,attrs:{"config":_vm.config,"hideDelete":!_vm.$_sys.isAllowed('scooping-visit-delete'),"hideDeleteSoft":!_vm.$_sys.isAllowed('scooping-visit-delete')},scopedSlots:_vm._u([{key:"create-form",fn:function(){return [_c('scooping-visit-form')]},proxy:true},{key:"list-projects",fn:function({ item }){return [_c('div',{staticClass:"scooping-project-wrapper min-w-200px"},[(item.project_data && item.project_data !== 'No Project Found')?_c('div',[_c('ol',{staticClass:"ml-0 pl-0",style:({
          'list-style-type':
            item.project_data.split(',').length > 1 ? 'auto' : 'none',
        })},_vm._l((item.project_data.split(',')),function(data){return _c('li',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(data.split(";").length > 1 ? data.split(";")[1] : "-")+" ")])}),0)]):_c('div',[_c('span',{staticClass:"text-italic text-grey"},[_vm._v("Project belum ditambahkan")])])])]}},{key:"list-action-update",fn:function({ item }){return [(
      item.status == 'document_saving' ||
      item.is_verify == 0 ||
      item.project_data == 'No Project Found' ||
      true
    )?_c('button',{staticClass:"geko-list-action-update",on:{"click":function($event){return _vm.$router.push({
        query: {
          view: 'update',
          id: item.id,
        },

        params: item,
      })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil-minus")])],1):_c('div')]}},{key:"list-village_area",fn:function({ item }){return [_c('div',{staticClass:"min-w-70px text-right"},[_c('span',[_vm._v(_vm._s(parseInt(item.land_area))+" Ha")])])]}},{key:"list-data_no",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column indicator-left"},[_c('p',{staticClass:"mb-0o text-link"},[_vm._v("#"+_vm._s(item.data_no))]),_c('div',{staticClass:"min-w-150px text-09-em"},[(item.start_scooping_date)?_c('span',[_vm._v(_vm._s(_vm._f("parse")(item.start_scooping_date,"date")))]):_vm._e(),(item.start_scooping_date && item.end_scooping_date)?_c('span',[_vm._v(" - ")]):_vm._e(),(item.end_scooping_date)?_c('span',[_vm._v(_vm._s(_vm._f("parse")(item.end_scooping_date,"date")))]):_vm._e()])])]}},{key:"list-potential_dusun",fn:function({ item }){return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.potential_dusun)+" dusun")])]}},{key:"list-project",fn:function({ item }){return [(false)?_c('div',{staticClass:"d-flex flex-col align-items-center min-w-300px"},[_c('ol',[_c('li',[_c('div',{staticClass:"d-flex flex-row pb-2",class:{
            'border-dotted-bottom': true,
          }},[_c('h5',{staticClass:"font-weight-400 mb-0 text-09-em"},[_vm._v(" Project Pembangunan Masjid ")]),_c('div',{staticClass:"d-flex flex-row",staticStyle:{"align-items":"flex-start"}},[_c('span',{staticClass:"badge bg-info text-08-em ml-2"},[_vm._v("Carbon")])])])])])]):_vm._e()]}},{key:"detail-row",fn:function(){return [_c('scooping-visit-detail')]},proxy:true},{key:"list-indicator",fn:function({ item }){return [_c('div',{staticClass:"indicator-wrapper pt-1"},[_c('div',{staticClass:"indicator",class:{
        warning:
          item.status == 'document_saving' &&
          item.is_verify == 0 &&
          item.project_data !== 'No Project Found',
        info:
          item.status == 'document_saving' &&
          item.is_verify == 1 &&
          item.project_data !== 'No Project Found',
        primary:
          item.status == 'ready_to_submit' &&
          item.project_data !== 'No Project Found',
        success:
          item.status == 'submit_review' &&
          item.project_data !== 'No Project Found' &&
          !['2022-05-05 00:00:00'].includes(item.created_at),
        danger:
          item.project_data == 'No Project Found' ||
          ['2022-05-05 00:00:00'].includes(item.created_at),
      }})])]}},{key:"list-status",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-row min-w-150px",staticStyle:{"justify-content":"center"}},[_c('span',{staticClass:"badge",class:{
        'bg-warning':
          item.status == 'document_saving' &&
          item.is_verify == 0 &&
          item.project_data != 'No Project Found',
        'bg-info':
          item.status == 'document_saving' &&
          item.is_verify == 1 &&
          item.project_data != 'No Project Found',
        'bg-primary':
          item.status == 'ready_to_submit' &&
          item.project_data != 'No Project Found',
        'bg-success':
          item.status == 'submit_review' &&
          item.project_data != 'No Project Found',
        'bg-danger':
          item.project_data == 'No Project Found' ||
          ['2022-05-05 00:00:00'].includes(item.created_at),
      }},[(['2022-05-05 00:00:00'].includes(item.created_at))?_c('span',[_vm._v("Data belum lengkap")]):(item.project_data == 'No Project Found')?_c('span',[_vm._v("Belum Assign Project")]):(item.status == 'document_saving' && item.is_verify == 0)?_c('span',[_vm._v("Pending")]):(item.status == 'document_saving' && item.is_verify == 1)?_c('span',[_vm._v("GIS Review")]):(item.status == 'ready_to_submit')?_c('span',[_vm._v("GIS Terverifikasi")]):(item.status == 'submit_review')?_c('span',[_vm._v("Terverifikasi")]):_vm._e()])])]}},{key:"list-potential_status",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-row justify-content-center min-w-150px"},[(item.status == 'submit_review' && item.potential_status != 4)?_c('span',{staticClass:"badge",class:{
        'bg-success': item.potential_status == 1,
        'bg-danger': item.potential_status == 0,
        'bg-info': item.potential_status == 2,
        'bg-light': item.potential_status == 3,
      }},[_vm._v(_vm._s(_vm._f("parse")(item.potential_status,"status-potential")))]):(item.status === 'submit_review' && item.potential_status == 4)?_c('span',[_c('span',{staticClass:"badge bg-info"},[_vm._v("Potensial Carbon")]),_c('span',{staticClass:"badge bg-light"},[_vm._v("Potensial Non Carbon")])]):_c('span',[_vm._v("-")])])]}},{key:"list-bottom-action",fn:function({ item }){return [(item.status == 'submit_review')?_c('v-btn',{staticClass:"mt-1",attrs:{"small":"","variant":"primary"},on:{"click":function($event){return _vm.onExport(item)}}},[(!_vm.exportIds.includes(item.id))?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-microsoft-word")]):_c('v-progress-circular',{attrs:{"color":"primary","size":15,"width":2,"indeterminate":""}}),_c('span',{staticClass:"text-09-em ml-2"},[_vm._v("Export")])],1):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }