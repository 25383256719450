var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"70%"},scopedSlots:_vm._u([{key:"default",fn:function({ isActive }){return [_c('v-card',[_c('v-card-title',[_vm._v(" Verifikasi GIS ")]),_c('v-card-text',[_c('ValidationObserver',{ref:"firstForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(_vm.scoopingVisitCode)?_c('v-row',{staticClass:"scooping-visit-gis-verification"},[_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("Data Lahan")])]),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Luas Lahan Kering',
                    validation: ['required'],
                    type: 'text',
                  }},model:{value:(_vm.formData.dry_land_area),callback:function ($$v) {_vm.$set(_vm.formData, "dry_land_area", $$v)},expression:"formData.dry_land_area"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Jenis Tanah',
                    validation: ['required'],
                    type: 'select',
                    option: {
                      multiple: true,
                      default_options: _vm.defaultData.land_type,
                      list_pointer: {
                        code: 'value',
                        label: 'text',
                        display: ['text'],
                      },
                    },
                  }},model:{value:(_vm.formData.land_type),callback:function ($$v) {_vm.$set(_vm.formData, "land_type", $$v)},expression:"formData.land_type"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Kelerengan Tanah',
                    validation: ['required'],
                    type: 'select',
                    option: {
                      multiple: true,
                      default_options: _vm.defaultData.slope,
                      list_pointer: {
                        code: 'value',
                        label: 'text',
                        display: ['text'],
                      },
                    },
                  }},model:{value:(_vm.formData.slope),callback:function ($$v) {_vm.$set(_vm.formData, "slope", $$v)},expression:"formData.slope"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Ketinggian Tanah',
                    validation: ['required'],
                    type: 'select',
                    option: {
                      multiple: true,
                      default_options: _vm.defaultData.altitude,
                      list_pointer: {
                        code: 'value',
                        label: 'text',
                        display: ['text'],
                      },
                    },
                  }},model:{value:(_vm.formData.altitude),callback:function ($$v) {_vm.$set(_vm.formData, "altitude", $$v)},expression:"formData.altitude"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Curah Hujan',
                    validation: ['required'],
                    type: 'select',
                    option: {
                      multiple: true,
                      default_options: _vm.defaultData.rainfall,
                      list_pointer: {
                        code: 'value',
                        label: 'text',
                        display: ['text'],
                      },
                    },
                  }},model:{value:(_vm.formData.rainfall),callback:function ($$v) {_vm.$set(_vm.formData, "rainfall", $$v)},expression:"formData.rainfall"}})],1),_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("Upload Polygon File (GIS)")])]),_c('v-col',{attrs:{"md":"12"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Polygon Desa',
                    validation: ['required'],
                    type: 'upload',
                    setter: 'village_polygon',
                    view_data: 'village_polygon',
                    api: 'scooping_visits/upload.php',

                    upload_type: '.kml',
                    directory: 'village_polygon',
                    option: {
                      icon: 'mdi-vector-polygon',
                      label_hint:
                        'Klik polygon untuk memilih file .kml yang akan diunggah',
                    },
                  }},model:{value:(_vm.formData.village_polygon),callback:function ($$v) {_vm.$set(_vm.formData, "village_polygon", $$v)},expression:"formData.village_polygon"}})],1),_c('v-col',{attrs:{"md":"12"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Polygon Lahan Kering / Kritis',
                    validation: [],
                    type: 'upload',
                    setter: 'dry_land_polygon',
                    upload_type: '.kml',
                    view_data: 'dry_land_polygon',
                    api: 'scooping_visits/upload.php',
                    directory: 'village_polygon',
                    option: {
                      icon: 'mdi-vector-polygon',
                      label_hint:
                        'Klik polygon untuk memilih file .kml yang akan diunggah',
                    },
                  }},model:{value:(_vm.formData.dry_land_polygon),callback:function ($$v) {_vm.$set(_vm.formData, "dry_land_polygon", $$v)},expression:"formData.dry_land_polygon"}})],1),_c('v-col',{staticClass:"d-flex flex-row justify-content-end",attrs:{"md":"12"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"variant":"light"},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" Batal ")]),_c('v-btn',{attrs:{"type":"submit","disabled":_vm.loading,"variant":"success"}},[_vm._v(" Verifikasi GIS ")])],1)],1):_vm._e()],1)]}}],null,true)})],1)],1)]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}})
}
var staticRenderFns = []

export { render, staticRenderFns }